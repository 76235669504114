import { Spin } from 'antd'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as CompletedIconBig } from 'assets/icons/tick-big.svg'
import { ReactComponent as CompletedIcon } from 'assets/icons/tick.svg'
import AlertModal from 'components/ui/Modal/AlertModal'
import { deviceWidth } from 'constants/responsive'
import {
  toggleSeriesFollowStatus,
  toggleSeriesFollowStatusVariables,
} from 'graphql/mutation/__generated__/toggleSeriesFollowStatus'
import { TOGGLE_SERIES_FOLLOW_STATUS } from 'graphql/mutation/toggleSeriesFollowStatus'
import { getSeriesWithPagination_getSeriesWithPagination_items } from 'graphql/query/__generated__/getSeriesWithPagination'
import { Fragment, MouseEvent, memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'

import { AddButton, AddButtonSpin, Image, PlusIconBig, Subtitle, Title, Wrapper } from './styled'

export type SeriesSize = 'small' | 'big' | 'huge'
type Props = {
  showAddBtn?: boolean
  showCount?: boolean
  selected?: boolean
  onAddBtnClick?: (selected?: boolean) => void
  size?: SeriesSize
  noClick?: boolean
} & getSeriesWithPagination_getSeriesWithPagination_items

function SeriesCard({
  seriesId,
  followInfo,
  size,
  coverUrl,
  title,
  subtitle,
  shiurimCount,
  showAddBtn,
  showCount,
  selected,
  noClick,
  onAddBtnClick,
}: Props) {
  const navigate = useNavigate()
  const [isUnfollowSeriesModalOpen, setIsUnfollowSeriesModalOpen] = useState(false)

  const [toggleSeriesFollow, { loading: toggleSeriesFollowLoading }] = useMutation<
    toggleSeriesFollowStatus,
    toggleSeriesFollowStatusVariables
  >(TOGGLE_SERIES_FOLLOW_STATUS)

  const openUnfollowSeriesModal = () => {
    setIsUnfollowSeriesModalOpen(true)
  }

  const closeUnfollowSeriesModal = () => {
    setIsUnfollowSeriesModalOpen(false)
  }

  const onToggleSeriesFollow = async (followed: boolean) => {
    await toggleSeriesFollow({ variables: { seriesId, follow: !followed } })

    closeUnfollowSeriesModal()
  }

  const onClick = () => {
    navigate(`/series/${seriesId}`)
  }

  const onAddButtonClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (toggleSeriesFollowLoading) return

    followInfo.isFollowed ? openUnfollowSeriesModal() : onToggleSeriesFollow(false)
  }

  const isSmall = size === 'small'
  const isBig = size === 'big'
  const isHuge = size === 'huge'

  const AddButtonWrapper = toggleSeriesFollowLoading ? (AddButtonSpin as typeof Spin) : Fragment

  const isMobile = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <>
      <Wrapper small={isSmall} big={isBig} huge={isHuge} onClick={noClick ? undefined : onClick}>
        <Image small={isSmall} big={isBig} huge={isHuge} src={`${process.env.REACT_APP_AWS_S3_URL}${coverUrl}`} />
        {showAddBtn && (
          <AddButton
            small={isSmall}
            big={isBig}
            huge={isHuge}
            isCompleted={selected}
            onClick={(e) => {
              e.stopPropagation()
              onAddBtnClick ? onAddBtnClick(!selected) : onAddButtonClick(e)
            }}
          >
            <AddButtonWrapper size={toggleSeriesFollowLoading && isMobile ? 'small' : undefined}>
              {selected ? (
                isHuge || isMobile ? (
                  <CompletedIconBig />
                ) : (
                  <CompletedIcon />
                )
              ) : isHuge || isMobile ? (
                <PlusIconBig />
              ) : (
                <PlusIcon />
              )}
            </AddButtonWrapper>
          </AddButton>
        )}
        {title && (
          <Title small={isSmall} big={isBig} huge={isHuge}>
            {title}
          </Title>
        )}
        {(subtitle || showCount) && (
          <Subtitle small={isSmall} big={isBig} huge={isHuge}>
            {showCount ? `${shiurimCount || 0} Shiurim` : subtitle}
          </Subtitle>
        )}
      </Wrapper>
      <AlertModal
        open={isUnfollowSeriesModalOpen}
        onCancel={closeUnfollowSeriesModal}
        onPrimaryBtnClick={() => onToggleSeriesFollow(true)}
        onSecondaryBtnClick={closeUnfollowSeriesModal}
        confirmLoading={toggleSeriesFollowLoading}
        title="Unfollow?"
        description="You can follow the Series back later again"
        primaryBtnText="UNFOLLOW"
        secondaryBtnText="CANCEL"
      />
    </>
  )
}

export default memo(SeriesCard)
