import { PopoverProps, Spin, message } from 'antd'
import { ReactComponent as MarkPlayed } from 'assets/icons/mark-played.svg'
import { ReactComponent as MarkUnplayed } from 'assets/icons/mark-unplayed.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/success-tick-white.svg'
import { COLORS } from 'constants/colors'
import { deviceWidth } from 'constants/responsive'
import dayjs from 'dayjs'
import { updateShiurStats, updateShiurStatsVariables } from 'graphql/mutation/__generated__/updateShiurStats'
import { UPDATE_SHIUR_STATS } from 'graphql/mutation/updateShiurStats'
import { getOneShiurim, getOneShiurimVariables } from 'graphql/query/__generated__/getOneShiurim'
import { getShiurimOrderedByDate_getShiurimOrderedByDate_items } from 'graphql/query/__generated__/getShiurimOrderedByDate'
import { GET_ONE_SHIURIM } from 'graphql/query/getOneShiurim'
import { useAuth } from 'hooks/useAuth'
import { DOWNLOADING_STATES, useDownloadAudio } from 'hooks/useDownloadAudio'
import { useNoteBookmarkModal } from 'hooks/useNoteBookmarkModal'
import { usePlayer } from 'hooks/usePlayer'
import { defaultPlaybackRate } from 'hooks/usePlayerOptions'
import { useQueryInfo } from 'hooks/useQueryInfo'
import { MouseEvent, PropsWithChildren, useState } from 'react'

import { useLazyQuery, useMutation } from '@apollo/client'

import { ShiurimAutoPlayInfo } from '../../Cards/ShiurimCard'
import AddNoteBookmarkModal from '../../Modals/AddNoteBookmarkModal'
import ShareShiurimModal, { OPTIONS } from '../../Modals/ShareShiurimModal'
import { CancelIcon, DownloadProgress, DownloadProgressWrapper } from '../../Player/PlayerAdditionalActions/styled'
import { AddNote, ShareSaved } from '../../Player/icons'
import { SuccessMessageTitle } from '../../Player/styled'
import { AddToQueueIcon, DownloadFinishedIcon, DownloadIcon, SaveIcon, SavedIcon, ShareIcon } from './icons'
import { PlaybackRateText, Popover, PopoverItem, PopoverText } from './styled'

type Props = PropsWithChildren<{
  shiur: getShiurimOrderedByDate_getShiurimOrderedByDate_items
  index: number
  full?: boolean
  player?: boolean
  playbackRate?: number
  onOpenPlaybackRate?: () => void
  onSaveToFavorite: (e: MouseEvent) => void
}> &
  PopoverProps

function ShiurActionsPopover({
  shiur,
  index,
  full,
  player,
  playbackRate,
  onOpenPlaybackRate,
  onSaveToFavorite,
  children,
  ...props
}: Props) {
  const { user } = useAuth()
  const { isInitialized, addToQueue } = usePlayer()
  const { downloadState, downloadingProgress, download, abortDownload } = useDownloadAudio(shiur.title, shiur.audioUrl)
  const { isAddNoteBookmarkModalOpen, onOpenAddNoteBookmarkModal, onCloseAddNoteBookmarkModal } = useNoteBookmarkModal()

  const { queryInfo } = useQueryInfo()
  const [shiurQueryInfo] = useState(queryInfo)

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState(false)

  const [getOneShiurim, { loading: getOneShiurimLoading }] = useLazyQuery<getOneShiurim, getOneShiurimVariables>(
    GET_ONE_SHIURIM,
    {
      fetchPolicy: 'network-only',
    }
  )
  const [updateShiurStats, { loading: updateShiurStatsLoading }] = useMutation<
    updateShiurStats,
    updateShiurStatsVariables
  >(UPDATE_SHIUR_STATS)

  const shiurimAutoPlayInfo: ShiurimAutoPlayInfo = { ...shiurQueryInfo, index }

  const configureMessagePlacement = () => {
    message.config({
      maxCount: 1,
      top: isInitialized ? window.innerHeight - 170 : window.innerHeight - 100,
      getContainer: () => document.querySelector('#root'),
    })
  }

  const onOpenShareModal = () => {
    setIsShareModalOpen(true)
    setIsPopoverOpen(false)
  }
  const onCloseShareModal = (updated?: boolean, options?: OPTIONS) => {
    setIsShareModalOpen(false)

    if (updated) {
      configureMessagePlacement()
      message.open({
        icon: <ShareSaved />,
        content: (
          <SuccessMessageTitle>
            {options === OPTIONS.LINK
              ? 'Link successfully copied to clipboard'
              : 'You can share the file from your device using native settings'}
          </SuccessMessageTitle>
        ),
      })
    }
  }

  const onAddToQueue = () => {
    addToQueue(shiur, shiurimAutoPlayInfo)
    setIsPopoverOpen(false)
  }

  const onAddNoteBookmark = () => {
    onOpenAddNoteBookmarkModal()
    setIsPopoverOpen(false)
  }

  const onPlaybackRateClick = () => {
    onOpenPlaybackRate()
    setIsPopoverOpen(false)
  }

  const onMarkAsPlayedOrUnplayed = async (played: boolean) => {
    await updateShiurStats({ variables: { shiurId: shiur.shiurId, duration: played ? shiur.duration : 0 } })
    await getOneShiurim({ variables: { shiurId: shiur.shiurId } })
    setIsPopoverOpen(false)

    message.config({
      maxCount: 1,
      top: window.innerHeight - 100,
      getContainer: () => document.querySelector('#root'),
    })
    message.open({
      icon: <SuccessIcon />,
      content: (
        <SuccessMessageTitle>Shiurim successfully marked as {played ? 'played' : 'unplayed'}</SuccessMessageTitle>
      ),
    })
  }

  const isMarkAsPlayedOrUnplayedLoading = getOneShiurimLoading || updateShiurStatsLoading

  const isPlayed = shiur.listenedDuration
    ? dayjs.duration(shiur.duration - shiur.listenedDuration).asSeconds() < 15
    : false

  const showPlaybackRate = window.innerWidth < Number(deviceWidth.tablet.match(/(\d+)/)[0])
  return (
    <>
      <Popover
        placement="bottomRight"
        trigger="click"
        open={user ? isPopoverOpen : false}
        onOpenChange={setIsPopoverOpen}
        {...props}
        content={
          <div onClick={(e) => e.stopPropagation()}>
            {full && (
              <>
                <PopoverItem onClick={onSaveToFavorite}>
                  {shiur.favoriteInfo.isFavorite ? <SavedIcon /> : <SaveIcon />}
                  <PopoverText>{shiur.favoriteInfo.isFavorite ? 'Remove' : 'Save'}</PopoverText>
                </PopoverItem>
                {player && (
                  <PopoverItem onClick={onAddNoteBookmark}>
                    <AddNote />
                    <PopoverText>Note or Bookmark</PopoverText>
                  </PopoverItem>
                )}
                {player && showPlaybackRate && (
                  <PopoverItem onClick={onPlaybackRateClick}>
                    <PlaybackRateText isChanged={playbackRate !== defaultPlaybackRate}>
                      {playbackRate.toFixed(1)}x
                    </PlaybackRateText>
                    <PopoverText>Playback Rate</PopoverText>
                  </PopoverItem>
                )}
                {!player && (
                  <PopoverItem onClick={onAddToQueue}>
                    <AddToQueueIcon />
                    <PopoverText>Add to queue</PopoverText>
                  </PopoverItem>
                )}
              </>
            )}
            <PopoverItem
              onClick={
                isMarkAsPlayedOrUnplayedLoading ? undefined : () => onMarkAsPlayedOrUnplayed(isPlayed ? false : true)
              }
            >
              {isMarkAsPlayedOrUnplayedLoading ? <Spin size="small" /> : isPlayed ? <MarkUnplayed /> : <MarkPlayed />}
              <PopoverText>Mark as {isPlayed ? 'unplayed' : 'played'}</PopoverText>
            </PopoverItem>
            <PopoverItem onClick={downloadState === DOWNLOADING_STATES.DOWNLOADING ? () => abortDownload : download}>
              {downloadState === DOWNLOADING_STATES.DOWNLOADING ? (
                <DownloadProgressWrapper>
                  <DownloadProgress
                    type="circle"
                    strokeWidth={10}
                    strokeColor={COLORS.primary.medium}
                    size={24}
                    percent={downloadingProgress}
                    showInfo={false}
                  />
                  <CancelIcon />
                </DownloadProgressWrapper>
              ) : downloadState === DOWNLOADING_STATES.DOWNLOADED ? (
                <DownloadFinishedIcon onClick={download} />
              ) : (
                <DownloadIcon onClick={download} />
              )}
              <PopoverText>Download</PopoverText>
            </PopoverItem>

            <PopoverItem onClick={onOpenShareModal}>
              <ShareIcon />
              <PopoverText>Share</PopoverText>
            </PopoverItem>
          </div>
        }
      >
        {children}
      </Popover>
      {isShareModalOpen && <ShareShiurimModal open={isShareModalOpen} onClose={onCloseShareModal} {...shiur} />}
      {isAddNoteBookmarkModalOpen && (
        <AddNoteBookmarkModal open={isAddNoteBookmarkModalOpen} onClose={onCloseAddNoteBookmarkModal} />
      )}
    </>
  )
}

export default ShiurActionsPopover
