import { Button } from 'antd'
import { ReactComponent as EditIcon } from 'assets/icons/edit-primary.svg'
import ChangeEmailModal from 'components/business/Main/Modals/ChangeEmailModal'
import ChangePasswordModal from 'components/business/Main/Modals/ChangePasswordModal'
import AlertModal from 'components/ui/Modal/AlertModal'
import { deleteUser } from 'graphql/mutation/__generated__/deleteUser'
import { DELETE_USER } from 'graphql/mutation/deleteUser'
import { sendSomethingWrongNotification } from 'helpers/sendSomethingWrongNotification'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'

import { useMutation } from '@apollo/client'

import { ActionWrapper, EditButton, Item, ItemContent, ItemLabel, ItemValue, Title, Wrapper } from '../styled'
import Notification from './Notification'

function Account() {
  const { user, signOut } = useAuth()
  const { email, provider } = user || {}

  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false)
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false)
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)

  const [deleteUser, { loading: isDeleteAccountLoading }] = useMutation<deleteUser>(DELETE_USER)

  const onDeleteUser = async () => {
    const { data } = await deleteUser()
    if (data.deleteUser) {
      await signOut()
    } else {
      sendSomethingWrongNotification()
    }
  }

  const onOpenDeleteAccountModal = () => setIsDeleteAccountModalOpen(true)
  const onCloseDeleteAccountModal = () => setIsDeleteAccountModalOpen(false)

  const onOpenChangeEmailModal = () => setIsChangeEmailModalOpen(true)
  const onCloseChangeEmailModal = () => setIsChangeEmailModalOpen(false)

  const onOpenChangePasswordModal = () => setIsChangePasswordModalOpen(true)
  const onCloseChangePasswordModal = () => setIsChangePasswordModalOpen(false)

  return (
    <>
      <Wrapper>
        <Title>Account and notifications settings</Title>
        <ul>
          <Item>
            <ItemContent>
              <ItemLabel>Email</ItemLabel>
              <ItemValue>{email}</ItemValue>
            </ItemContent>
            <ActionWrapper>
              <EditButton onClick={onOpenChangeEmailModal}>
                <EditIcon />
              </EditButton>
            </ActionWrapper>
          </Item>
          <Item>
            <ItemContent>
              <ItemLabel>Password</ItemLabel>
              <ItemValue>{provider ? 'This user is connected to your Google account' : '••••••••••'}</ItemValue>
            </ItemContent>
            {!provider && (
              <ActionWrapper>
                <EditButton onClick={onOpenChangePasswordModal}>
                  <EditIcon />
                </EditButton>
              </ActionWrapper>
            )}
          </Item>
          <Notification />
          <Button danger onClick={onOpenDeleteAccountModal}>
            Delete Account
          </Button>
        </ul>
      </Wrapper>
      {isChangeEmailModalOpen && <ChangeEmailModal open={isChangeEmailModalOpen} onClose={onCloseChangeEmailModal} />}
      {isChangePasswordModalOpen && (
        <ChangePasswordModal open={isChangePasswordModalOpen} onClose={onCloseChangePasswordModal} />
      )}
      {isDeleteAccountModalOpen && (
        <AlertModal
          open={isDeleteAccountModalOpen}
          onCancel={onCloseDeleteAccountModal}
          onPrimaryBtnClick={() => onDeleteUser()}
          onSecondaryBtnClick={onCloseDeleteAccountModal}
          confirmLoading={isDeleteAccountLoading}
          title="Delete account?"
          description="It will permanently delete your account"
          primaryBtnText="DELETE"
          secondaryBtnText="CANCEL"
        />
      )}
    </>
  )
}

export default Account
