import { Flex } from 'components/common/Flex'
import {
  getRecommendedShiurim,
  getRecommendedShiurimVariables,
} from 'graphql/query/__generated__/getRecommendedShiurim'
import { GET_RECOMMENDED_SHIURIM } from 'graphql/query/getRecommendedShiurim'
import { useQueryInfo, withQueryInfo } from 'hooks/useQueryInfo'
import { useEffect, useState } from 'react'
import { ROUTE_PATH } from 'routes'

import { useQuery } from '@apollo/client'

import { TitleDivider, ViewAllLink } from '../Following/styled'
import { Title } from '../NewAndRecommended/styled'
import ShiurimList from '../ShiurimList'

type Props = { openRegisterToContinueModal: () => void }

const initialPagination = {
  take: 12,
  page: 1,
}

function Recommended({ openRegisterToContinueModal }: Props) {
  const { setQueryInfo } = useQueryInfo()

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const { data, loading, fetchMore } = useQuery<getRecommendedShiurim, getRecommendedShiurimVariables>(
    GET_RECOMMENDED_SHIURIM,
    {
      variables: initialPagination,
      fetchPolicy: 'cache-and-network',
    }
  )

  useEffect(() => {
    setQueryInfo({
      query: GET_RECOMMENDED_SHIURIM,
      variables: initialPagination,
    })
  }, [setQueryInfo])

  const onFetchMore = async () => {
    setLoadingNext(true)

    const variables = { take: initialPagination.take, page: page + 1 }
    await fetchMore({
      variables,
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getRecommendedShiurim: {
            ...previousQueryResult.getRecommendedShiurim,
            ...fetchMoreResult.getRecommendedShiurim,
            items: [...previousQueryResult.getRecommendedShiurim.items, ...fetchMoreResult.getRecommendedShiurim.items],
          },
        }
      },
    })

    setQueryInfo({
      query: GET_RECOMMENDED_SHIURIM,
      variables,
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }
  return (
    <Flex column>
      <Flex alignCenter>
        <Title>Recommended</Title>
        <TitleDivider />
        <ViewAllLink to={ROUTE_PATH.RECOMMENDED}>View all</ViewAllLink>
      </Flex>
      <ShiurimList
        items={data?.getRecommendedShiurim?.items}
        next={data?.getRecommendedShiurim?.meta?.next}
        loading={loading}
        loadingNext={loadingNext}
        onFetchMore={onFetchMore}
        openRegisterToContinueModal={openRegisterToContinueModal}
      />
    </Flex>
  )
}

export default withQueryInfo(Recommended)
