import { SponsorshipPeriod } from '__generated__/globalTypes'
import { Checkbox, Input } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import { ReactComponent as RightArrowIcon } from 'assets/icons/right-arrow.svg'
import { ReactComponent as Icon } from 'assets/icons/sponsor-app.svg'
import { Flex } from 'components/common/Flex'
import TextArea from 'components/ui/FormElements/TextArea'
import dayjs from 'dayjs'
import { useAuth } from 'hooks/useAuth'
import { useHandleDonationModals } from 'hooks/useHandleDonationModals'
import { useHandleDonationSelectDate } from 'hooks/useHandleDonationSelectDate'
import { useCallback, useEffect, useRef, useState } from 'react'

import SelectDayModal from '../../Modals/SelectDayModal'
import SelectMonthModal from '../../Modals/SelectMonthModal'
import SelectWeekModal from '../../Modals/SelectWeekModal'
import DonateTypeSelect, { donateTypeToLabel, donateTypeToPriceApp } from '../DonateTypeSelect'
import PaymentModal from '../PaymentModal'
import { calculateAmountOfDays } from '../calculateAmountOfDays'
import { createPaymentCardForm } from '../createPaymentCardForm'
import { getDateValue } from '../getDateValue'
import { getDaysForCustom } from '../getDaysForCustom'
import {
  Banner,
  Content,
  ExtraAccent,
  ExtraText,
  Form,
  FormItem,
  IconWrapper,
  InputNumber,
  ShiurRowWrapper,
  SubmitButton,
  Subtitle,
  Title,
  Wrapper,
} from '../styled'

export type Values = {
  donationType: SponsorshipPeriod
  date: [dayjs.Dayjs, dayjs.Dayjs]
  amount?: number
  comment?: string
  isAnonymous: boolean
  isInMemory: boolean
  inMemoryOf?: string
  fullName?: string
  email?: string
}

function SponsorApp() {
  const { user } = useAuth()

  const cardFormRef = useRef<CardForm>()

  const [isErrors, setIsErrors] = useState(false)

  const [form] = useForm()
  const {
    isSelectDateModalOpen,
    isPaymentModalOpen,
    openSelectDateModal,
    openPaymentModal,
    closeSelectDateModal,
    closePaymentModal,
  } = useHandleDonationModals()
  const { onSelectDay, onSelectWeek, onSelectMonth } = useHandleDonationSelectDate(form, closeSelectDateModal)

  const donationType = useWatch<SponsorshipPeriod>('donationType', { form })
  const date = useWatch<[dayjs.Dayjs, dayjs.Dayjs]>('date', { form })
  const amount = useWatch('amount', { form })
  const comment = useWatch('comment', { form })
  const isAnonymous = useWatch('isAnonymous', { form })
  const isInMemory = useWatch('isInMemory', { form })
  const inMemoryOf = useWatch('inMemoryOf', { form })
  const fullName = useWatch('fullName', { form })
  const email = useWatch('email', { form })

  const values = {
    donationType,
    date: donationType === SponsorshipPeriod.custom ? getDaysForCustom(amount, true) : date,
    amount: donationType === SponsorshipPeriod.custom ? amount : donateTypeToPriceApp[donationType],
    comment,
    isAnonymous,
    isInMemory,
    inMemoryOf,
    fullName,
    email,
  } as Values

  useEffect(() => {
    form.setFieldValue('date', undefined)
  }, [donationType, form])

  const validateFields = useCallback(() => {
    form
      .validateFields()
      .then(() => setIsErrors(false))
      .catch(() => {
        setIsErrors(true)
      })
  }, [form])

  useEffect(() => {
    if (inMemoryOf) {
      validateFields()
    }
  }, [inMemoryOf, form, validateFields])

  useEffect(() => {
    if (fullName && email) {
      validateFields()
    }
  }, [fullName, email, form, validateFields])

  const onSelectDonationType = (donationType: SponsorshipPeriod) => {
    form.setFieldValue('donationType', donationType)
  }

  const onFinish = () => {
    cardFormRef.current = createPaymentCardForm()

    openPaymentModal()
  }

  const dateValue = getDateValue(donationType, date)

  const isDisabled =
    (!user && (!fullName || !email)) ||
    (isInMemory && !inMemoryOf) ||
    (donationType === SponsorshipPeriod.custom ? !amount : !date) ||
    isErrors

  return (
    <>
      <Wrapper left>
        <Banner app>
          <IconWrapper>
            <Icon />
          </IconWrapper>
          <Title>Sponsor Path4life</Title>
          <Subtitle>Sponsor our App</Subtitle>
        </Banner>
        <Content>
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              donationType: SponsorshipPeriod.day,
              date: undefined,
              isAnonymous: false,
              isInMemory: false,
            }}
            onFinish={onFinish}
          >
            <FormItem name="donationType">
              <DonateTypeSelect isApp value={donationType} onChange={onSelectDonationType} />
            </FormItem>
            {!user && (
              <ShiurRowWrapper>
                <Flex>
                  <FormItem
                    isInRow
                    name="fullName"
                    validateTrigger={form.getFieldError('fullName').length ? 'onChange' : 'onBlur'}
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                      { pattern: new RegExp('^[-\\sa-zA-Z]+$'), message: 'Incorrect full name' },
                    ]}
                  >
                    <Input placeholder="Enter your full name" maxLength={100} />
                  </FormItem>
                  <FormItem
                    isInRow
                    name="email"
                    validateTrigger={form.getFieldError('email').length ? 'onChange' : 'onBlur'}
                    rules={[
                      { required: true, message: '' },
                      {
                        type: 'email',
                        message: 'Incorrect email',
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email address" />
                  </FormItem>
                </Flex>
              </ShiurRowWrapper>
            )}
            {donationType === SponsorshipPeriod.custom ? (
              <FormItem
                name="amount"
                extra={
                  <ExtraText>
                    <ExtraAccent>
                      The specified amount will sponsor approx. {calculateAmountOfDays(amount, true)} days of Path4Life.
                    </ExtraAccent>{' '}
                    If you would like to specify which particular day, week or month please let us know in the comment
                    field below.
                  </ExtraText>
                }
              >
                <InputNumber type="number" min={1} controls={false} placeholder="Enter amount" prefix="$" />
              </FormItem>
            ) : (
              <FormItem name="date" isButton>
                <div onClick={openSelectDateModal}>
                  <Input
                    placeholder={`Select ${donateTypeToLabel[donationType]?.toLowerCase()}`}
                    prefix={<CalendarIcon />}
                    suffix={<RightArrowIcon />}
                    value={dateValue}
                    style={{ pointerEvents: 'none' }}
                    disabled
                  />
                </div>
              </FormItem>
            )}
            <FormItem name="comment" label="Post a Comment (Optional)">
              <TextArea showCount maxLength={150} rows={3} />
            </FormItem>
            <FormItem name="isAnonymous" valuePropName="checked" isCheckbox>
              <Checkbox>Remain anonymous</Checkbox>
            </FormItem>
            <FormItem name="isInMemory" valuePropName="checked" isCheckbox>
              <Checkbox>Add dedication message?</Checkbox>
            </FormItem>
            {isInMemory && (
              <FormItem
                name="inMemoryOf"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                  { pattern: new RegExp('^[\\sa-zA-Z.,!?\'"()\\-]+$'), message: 'Incorrect dedication message' },
                ]}
              >
                <Input placeholder="Dedication message..." showCount maxLength={100} />
              </FormItem>
            )}
            <FormItem>
              <SubmitButton disabled={isDisabled} type="primary" htmlType="submit">
                Donate・{donateTypeToLabel[donationType]?.toUpperCase()}・$
                {donationType === SponsorshipPeriod.custom ? amount || 0 : donateTypeToPriceApp[donationType]}
              </SubmitButton>
            </FormItem>
          </Form>
        </Content>
      </Wrapper>
      {donationType === SponsorshipPeriod.day && isSelectDateModalOpen && (
        <SelectDayModal
          open={isSelectDateModalOpen}
          onClose={closeSelectDateModal}
          selectedDate={date?.[0]}
          onChangeDate={onSelectDay}
        />
      )}
      {donationType === SponsorshipPeriod.weekly && isSelectDateModalOpen && (
        <SelectWeekModal
          open={isSelectDateModalOpen}
          onClose={closeSelectDateModal}
          selectedDates={date}
          onChangeDate={onSelectWeek}
        />
      )}
      {donationType === SponsorshipPeriod.monthly && isSelectDateModalOpen && (
        <SelectMonthModal
          open={isSelectDateModalOpen}
          onClose={closeSelectDateModal}
          selectedDates={date}
          onChangeDate={onSelectMonth}
        />
      )}
      {isPaymentModalOpen && (
        <PaymentModal
          isApp
          cardForm={cardFormRef}
          open={isPaymentModalOpen}
          values={values}
          onClose={closePaymentModal}
        />
      )}
    </>
  )
}

export default SponsorApp
