import ShiurimList from 'components/business/Main/Lists/ShiurimList'
import ShiurimFilter, { ShiurimFilters } from 'components/business/Main/Shiurim/ShiurimFilter'
import Header from 'components/ui/Header'
import AlertModal from 'components/ui/Modal/AlertModal'
import { getAllShiurims, getAllShiurimsVariables } from 'graphql/query/__generated__/getAllShiurims'
import { GET_ALL_SHIURIMS } from 'graphql/query/getAllShiurims'
import { useHeaderHeight } from 'hooks/useHeaderHeight'
import { useQueryInfo, withQueryInfo } from 'hooks/useQueryInfo'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useQuery } from '@apollo/client'

import { FiltersWrapper, Wrapper } from './styled'

const initialPagination = {
  take: 30,
  page: 1,
}

function TopicDetailed() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const { setQueryInfo } = useQueryInfo()
  const { headerHeight } = useHeaderHeight()

  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)

  const [filters, setShiurimFilters] = useState<ShiurimFilters>({})

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const variables = useMemo(
    () => ({
      ...initialPagination,
      searchParams: {
        played: filters.played?.value,
        startDate: filters.period?.startDate,
        endDate: filters.period?.endDate,
        series: filters.series?.map((el) => el.id),
        topics: [id],
        subtopics: filters.subtopics?.map((el) => el.id),
      },
    }),
    [filters.period?.endDate, filters.period?.startDate, filters.played?.value, filters.series, filters.subtopics, id]
  )
  const { data, loading, fetchMore } = useQuery<getAllShiurims, getAllShiurimsVariables>(GET_ALL_SHIURIMS, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setQueryInfo({ query: GET_ALL_SHIURIMS, variables })
  }, [setQueryInfo, variables])

  const onFetchMore = async () => {
    setLoadingNext(true)

    const variables = { take: initialPagination.take, page: page + 1 }
    await fetchMore({
      variables,
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getAllShiurims: {
            ...previousQueryResult.getAllShiurims,
            ...fetchMoreResult.getAllShiurims,
            items: [...previousQueryResult.getAllShiurims.items, ...fetchMoreResult.getAllShiurims.items],
          },
        }
      },
    })

    setQueryInfo({ query: GET_ALL_SHIURIMS, variables })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)

  return (
    <>
      <Header title={`Topic: ${searchParams.get('title')}`} isBackButton />
      <FiltersWrapper>
        <ShiurimFilter withQuickFilters noTopics filters={filters} onChangeFilters={setShiurimFilters} />
      </FiltersWrapper>
      <Wrapper id="scrollable-wrapper" headerHeight={headerHeight}>
        <ShiurimList
          items={data?.getAllShiurims?.items}
          next={data?.getAllShiurims?.meta?.next}
          onFetchMore={onFetchMore}
          openRegisterToContinueModal={openRegisterToContinueModal}
          loading={loading}
          loadingNext={loadingNext}
        />
      </Wrapper>
      <AlertModal
        open={isRegisterToContinueModalOpen}
        onCancel={closeRegisterToContinueModal}
        onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
        onSecondaryBtnClick={closeRegisterToContinueModal}
        title="Register to Continue"
        description="To unlock all features you need to create an account"
        primaryBtnText="REGISTER"
        secondaryBtnText="NOT NOW"
      />
    </>
  )
}

export default withQueryInfo(TopicDetailed)
