/* eslint-disable no-console */
import { message } from 'antd'
import { ReactComponent as SuccessIcon } from 'assets/icons/success-tick-white.svg'
import { ShiurimAutoPlayInfo } from 'components/business/Main/Cards/ShiurimCard'
import { SuccessMessageTitle } from 'components/business/Main/Player/styled'
import { getShiurimOrderedByDate_getShiurimOrderedByDate_items } from 'graphql/query/__generated__/getShiurimOrderedByDate'
import { MutableRefObject, ReactNode, createContext, useCallback, useContext, useState } from 'react'
import ReactPlayer from 'react-player'
import { OnProgressProps } from 'react-player/base'

import { useFetchNewShiursForAutoPlay } from './useFetchNewShiursForAutoPlay'

export type Shiur = getShiurimOrderedByDate_getShiurimOrderedByDate_items
export type QueueItem = Shiur & { shiurAutoPlayInfo: ShiurimAutoPlayInfo }

export type Context = {
  playerRef?: MutableRefObject<ReactPlayer>
  id?: string
  isInitialized: boolean
  isPlaying: boolean
  url?: string
  duration?: number
  progress?: OnProgressProps
  shiur?: Shiur
  shiurAutoPlayInfo?: ShiurimAutoPlayInfo & { fromNext?: boolean; fromPrev?: boolean }
  isAutoPlayEnabled: boolean
  queue: QueueItem[]
  positionInQueue: number
  actions: {
    setPlayerRef: (playerRef: MutableRefObject<ReactPlayer>) => void
    play: (shiur?: Shiur, shiurAutoPlayInfo?: ShiurimAutoPlayInfo) => Promise<void>
    pause: () => void
    next: () => void
    previous: () => void
    toggleAutoPlay: () => Promise<void>
    updatePlayerState: (duration: number, progress: OnProgressProps) => void
    addToQueue: (shiur: Shiur, shiurAutoPlayInfo: ShiurimAutoPlayInfo) => void
    bulkAddToQueue: (shiurs: QueueItem[], play?: boolean) => void
    removeFromQueue: (shiurimId: string) => void
    clearQueue: () => void
    destroy: () => void
  }
}

const PlayerContext = createContext<Context>({
  playerRef: undefined,
  id: undefined,
  isInitialized: false,
  isPlaying: false,
  url: undefined,
  duration: undefined,
  progress: undefined,
  shiur: undefined,
  shiurAutoPlayInfo: undefined,
  isAutoPlayEnabled: false,
  queue: [],
  positionInQueue: 0,
  actions: {
    setPlayerRef: () => undefined,
    play: () => undefined,
    pause: () => undefined,
    next: () => undefined,
    previous: () => undefined,
    toggleAutoPlay: () => undefined,
    updatePlayerState: () => undefined,
    addToQueue: () => undefined,
    bulkAddToQueue: () => undefined,
    removeFromQueue: () => undefined,
    clearQueue: () => undefined,
    destroy: () => undefined,
  },
})

export const PlayerProvider = (props: { children?: ReactNode }) => {
  const [state, setState] = useState(useContext(PlayerContext))

  const fetchNewShiursForAutoPlay = useFetchNewShiursForAutoPlay(state, setState)

  const setPlayerRef = useCallback((playerRef: MutableRefObject<ReactPlayer>) => {
    setState((prev) => ({ ...prev, playerRef }))
  }, [])

  const init = useCallback((shiur: Shiur, shiurAutoPlayInfo?: ShiurimAutoPlayInfo) => {
    setState((prev) => ({
      ...prev,
      id: shiur.shiurId,
      isInitialized: true,
      isPlaying: true,
      url: shiur.audioUrl,
      shiur: shiur,
      shiurAutoPlayInfo: { ...shiurAutoPlayInfo, fromNext: false, fromPrev: false },
    }))
  }, [])

  const addToQueue = useCallback(
    (shiur: Shiur, shiurAutoPlayInfo: ShiurimAutoPlayInfo) => {
      const isDuplicateShiur = state.queue.some((el) => el.shiurId === shiur.shiurId)
      if (!isDuplicateShiur)
        setState((prev) => ({
          ...prev,
          queue: [...prev.queue.filter((el) => el.shiurId !== shiur.shiurId), { ...shiur, shiurAutoPlayInfo }],
        }))

      message.config({
        maxCount: 1,
        top: state.isInitialized ? window.innerHeight - 170 : window.innerHeight - 100,
        getContainer: () => document.querySelector('#root'),
      })
      message.open({
        icon: <SuccessIcon />,
        content: (
          <SuccessMessageTitle>
            {isDuplicateShiur ? 'Shiurim is already added to queue' : 'Shiurim successfully added to queue'}
          </SuccessMessageTitle>
        ),
      })
    },
    [state.isInitialized, state.queue]
  )

  const bulkAddToQueue = useCallback(
    (shiurs: QueueItem[], play?: boolean) => {
      2
      if (!shiurs.length) return

      if (play) {
        const shiur = shiurs[0]
        setState((prev) => ({
          ...prev,
          id: shiur.shiurId,
          isInitialized: true,
          isPlaying: true,
          url: shiur.audioUrl,
          shiur: shiur,
          shiurAutoPlayInfo: { ...shiur.shiurAutoPlayInfo, fromNext: false, fromPrev: false },
          positionInQueue: 1,
          queue: shiurs,
        }))
      } else {
        setState((prev) => ({
          ...prev,
          queue: shiurs,
        }))
      }

      message.config({
        maxCount: 1,
        top: state.isInitialized ? window.innerHeight - 170 : window.innerHeight - 100,
        getContainer: () => document.querySelector('#root'),
      })
      message.open({
        icon: <SuccessIcon />,
        content: <SuccessMessageTitle>Shiurim successfully added to queue</SuccessMessageTitle>,
      })
    },
    [state.isInitialized]
  )

  const removeFromQueue = useCallback((shiurimId: string) => {
    setState((prev) => ({ ...prev, queue: [...prev.queue.filter((el) => el.shiurId !== shiurimId)] }))
  }, [])

  const clearQueue = useCallback(() => {
    setState((prev) => ({ ...prev, queue: [] }))
  }, [])

  const play = useCallback(
    async (shiur?: Shiur, shiurAutoPlayInfo?: ShiurimAutoPlayInfo, fromNext?: boolean, fromPrev?: boolean) => {
      if (state.isInitialized && !shiur) {
        return setState((prev) => ({ ...prev, isPlaying: true }))
      }

      if (state.isInitialized && state.id !== shiur.shiurId) {
        setState((prev) => ({
          ...prev,
          id: shiur.shiurId,
          isPlaying: true,
          url: shiur.audioUrl,
          shiur,
        }))

        if (state.isAutoPlayEnabled && !fromPrev) {
          setState((prev) => ({ ...prev, shiurAutoPlayInfo: { ...shiurAutoPlayInfo, fromNext, fromPrev } }))
        }
        return
      }

      if (shiur) {
        return init(shiur, shiurAutoPlayInfo)
      }

      if (state.queue.length) {
        init(state.queue[0])
        removeFromQueue(state.queue[0].shiurId)
      }
    },
    [init, removeFromQueue, state.id, state.isAutoPlayEnabled, state.isInitialized, state.queue]
  )

  const pause = useCallback(() => {
    setState((prev) => ({ ...prev, isPlaying: false }))
  }, [])

  const previous = useCallback(() => {
    if (state.positionInQueue <= 1 || state.queue.length === 0) return

    const newPosition = state.positionInQueue - 1
    setState((prev) => ({ ...prev, positionInQueue: newPosition }))

    const { shiurAutoPlayInfo, ...shiur } = state.queue[newPosition - 1]
    play(shiur, shiurAutoPlayInfo, false, true)
  }, [play, state.positionInQueue, state.queue])

  const next = useCallback(async () => {
    if (state.positionInQueue >= state.queue.length || state.queue.length === 0) return

    const newPosition = state.positionInQueue + 1
    setState((prev) => ({ ...prev, positionInQueue: newPosition }))

    const { shiurAutoPlayInfo, ...shiur } = state.queue[newPosition - 1]
    play(shiur, shiurAutoPlayInfo, true)
  }, [state.positionInQueue, state.queue, play])

  const toggleAutoPlay = useCallback(async () => {
    if (!state.isAutoPlayEnabled) {
      await fetchNewShiursForAutoPlay(state.shiurAutoPlayInfo)

      setState((prev) => ({ ...prev, isAutoPlayEnabled: !prev.isAutoPlayEnabled }))
    } else {
      setState((prev) => ({ ...prev, isAutoPlayEnabled: !prev.isAutoPlayEnabled, queue: [] }))
    }
  }, [fetchNewShiursForAutoPlay, state.isAutoPlayEnabled, state.shiurAutoPlayInfo])

  const updatePlayerState = useCallback((duration: number, progress: OnProgressProps) => {
    setState((prev) => ({ ...prev, duration, progress }))
  }, [])

  const destroy = useCallback(() => {
    setState((prev) => ({ ...prev, id: undefined, isInitialized: false, isPlaying: false }))
  }, [])

  return (
    <PlayerContext.Provider
      value={{
        ...state,
        actions: {
          setPlayerRef,
          play,
          pause,
          previous,
          next,
          toggleAutoPlay,
          updatePlayerState,
          addToQueue,
          bulkAddToQueue,
          removeFromQueue,
          clearQueue,
          destroy,
        },
      }}
      {...props}
    />
  )
}

export function usePlayer() {
  const ctx = useContext(PlayerContext)

  return {
    playerRef: ctx.playerRef,
    id: ctx.id,
    isInitialized: ctx.isInitialized,
    isPlaying: ctx.isPlaying,
    isAutoPlayEnabled: ctx.isAutoPlayEnabled,
    url: ctx.url,
    duration: ctx.duration,
    progress: ctx.progress,
    shiur: ctx.shiur,
    queue: ctx.queue,
    positionInQueue: ctx.positionInQueue,
    ...ctx.actions,
  }
}
