import { Form, Input } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import ActionModal from 'components/ui/Modal/ActionModal'
import { useAuth } from 'hooks/useAuth'
import { ErrorText, FakeLink, FormItem } from 'pages/Auth/styled'
import { useEffect, useState } from 'react'

import { Auth } from '@aws-amplify/auth'

const { Password } = Input

type Props = { open: boolean; onClose: () => void; showVerificationCodeSent: () => void; nextStep: () => void }

function PasswordsInput({ open, onClose, showVerificationCodeSent, nextStep }: Props) {
  const { changePassword, resetPassword } = useAuth()

  const [form] = useForm()
  const oldPassword = useWatch('oldPassword', { form })
  const newPassword = useWatch('newPassword', { form })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const [isErrors, setIsErrors] = useState(false)

  useEffect(() => {
    if (oldPassword && newPassword) {
      validateAndUpdateState()
    }
  }, [oldPassword, newPassword, form])

  const validateAndUpdateState = () => {
    form
      .validateFields()
      .then(() => setIsErrors(false))
      .catch(() => {
        setIsErrors(true)
      })
  }

  const onBtnClick = async () => {
    setIsLoading(true)
    setError('')

    try {
      await changePassword(oldPassword, newPassword)
      nextStep()
    } catch (e) {
      if (e.message === 'Incorrect username or password.') {
        setError('Invalid current password.')
      } else {
        setError(e.message)
      }
    }

    setIsLoading(false)
  }

  const onForgotPassword = async () => {
    const user = await Auth.currentAuthenticatedUser()
    await resetPassword(user.username)
    showVerificationCodeSent()
  }

  const isBtnDisabled = !oldPassword || !newPassword || isErrors

  return (
    <ActionModal
      open={open}
      title="Change password"
      width={400}
      btnText="CONFIRM NEW PASSWORD"
      onBtnClick={onBtnClick}
      onCancel={onClose}
      btnLoading={isLoading}
      disabled={isBtnDisabled}
    >
      <Form form={form} layout="vertical" requiredMark={false} onValuesChange={() => setError('')}>
        <FormItem
          name="oldPassword"
          label="Enter your Current Password"
          validateTrigger={form.getFieldError('oldPassword').length ? 'onChange' : 'onBlur'}
          required
          rules={[
            {
              required: true,
              message: '',
            },
            {
              min: 8,
              max: 20,
              message: 'Incorrect password',
            },
          ]}
        >
          <Password placeholder="Current Password" type="password" status={error ? 'error' : undefined} />
        </FormItem>
        <FakeLink onClick={onForgotPassword}>Forgot password</FakeLink>
        <FormItem
          name="newPassword"
          label="Enter your New Password"
          required
          rules={[
            {
              required: true,
              message: '',
            },
            {
              min: 8,
              message: 'Password must be at least 8 characters long',
            },
            {
              max: 20,
              message: 'Password must be at most 20 characters long',
            },
          ]}
        >
          <Password placeholder="Enter New Password" type="password" status={error ? 'error' : undefined} />
        </FormItem>
        {error && <ErrorText>{error}</ErrorText>}
      </Form>
    </ActionModal>
  )
}

export default PasswordsInput
