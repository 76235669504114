/* eslint-disable eqeqeq */
import { Spin } from 'antd'
import { ReactComponent as NextIcon } from 'assets/icons/slider-arrow-next.svg'
import { ReactComponent as PreviousIcon } from 'assets/icons/slider-arrow-previous.svg'
import SeriesCard from 'components/business/Main/Cards/SeriesCard'
import { EmptyContainer, EmptyText } from 'components/business/Main/Home/styled'
import { Flex } from 'components/common/Flex'
import AlertModal from 'components/ui/Modal/AlertModal'
import {
  toggleSeriesFollowStatus,
  toggleSeriesFollowStatusVariables,
} from 'graphql/mutation/__generated__/toggleSeriesFollowStatus'
import { TOGGLE_SERIES_FOLLOW_STATUS } from 'graphql/mutation/toggleSeriesFollowStatus'
import { getSeriesOrderedByShiurCount_getSeriesOrderedByShiurCount } from 'graphql/query/__generated__/getSeriesOrderedByShiurCount'
import { useAuth } from 'hooks/useAuth'
import { Ref, useRef, useState } from 'react'
import { ROUTE_PATH } from 'routes'
import { Swiper, SwiperClass, SwiperRef } from 'swiper/react'

import { useMutation } from '@apollo/client'

import { Title, TitleDivider, TitleRow, ViewAllLink } from '../styled'
import { ButtonsContainer, ListWrapper, SliderButton, SwiperSlide } from './styled'

type Props = { data?: getSeriesOrderedByShiurCount_getSeriesOrderedByShiurCount; loading: boolean; showCount: boolean }

function Series({ data, loading, showCount }: Props) {
  const { user } = useAuth()

  const swiperRef: Ref<SwiperRef> = useRef()
  const [isUnfollowSeriesModalOpen, setIsUnfollowSeriesModalOpen] = useState(false)
  const [selectedSeriesId, setSelectedSeriesId] = useState<string>()
  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true)
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false)

  const [toggleSeriesFollow, { loading: toggleSeriesFollowLoading }] = useMutation<
    toggleSeriesFollowStatus,
    toggleSeriesFollowStatusVariables
  >(TOGGLE_SERIES_FOLLOW_STATUS)

  const openUnfollowSeriesModal = (seriesId: string) => {
    setSelectedSeriesId(seriesId)
    setIsUnfollowSeriesModalOpen(true)
  }

  const closeUnfollowSeriesModal = () => {
    setSelectedSeriesId(undefined)
    setIsUnfollowSeriesModalOpen(false)
  }

  const onToggleSeriesFollow = async (seriesId: string, followed: boolean) => {
    await toggleSeriesFollow({ variables: { seriesId, follow: !followed } })

    closeUnfollowSeriesModal()
  }

  const onSlideChange = (swiper: SwiperClass) => {
    if (swiper.isBeginning) setIsPrevBtnDisabled(true)
    if (swiper.isEnd) setIsNextBtnDisabled(true)

    if (!swiper.isBeginning) setIsPrevBtnDisabled(false)
    if (!swiper.isEnd) setIsNextBtnDisabled(false)
  }

  const isItems = !!data?.items?.length

  return (
    <>
      <div>
        <TitleRow withButtons>
          <Flex alignCenter>
            <Title>Series {data?.meta?.total != null && showCount ? `・ ${data.meta.total}` : ''}</Title>
            <TitleDivider />
            <ViewAllLink to={ROUTE_PATH.SERIES}>View all</ViewAllLink>
          </Flex>
          {!loading && isItems && (
            <ButtonsContainer>
              <SliderButton disabled={isPrevBtnDisabled} onClick={() => swiperRef.current.swiper.slidePrev()}>
                <PreviousIcon />
              </SliderButton>
              <SliderButton disabled={isNextBtnDisabled} onClick={() => swiperRef.current.swiper.slideNext()}>
                <NextIcon />
              </SliderButton>
            </ButtonsContainer>
          )}
        </TitleRow>
        <ListWrapper>
          {loading ? (
            <EmptyContainer>
              <Spin />
            </EmptyContainer>
          ) : isItems ? (
            <Swiper
              slidesPerView="auto"
              slidesPerGroupAuto
              ref={swiperRef}
              spaceBetween={14}
              onInit={onSlideChange}
              onReachBeginning={onSlideChange}
              onReachEnd={onSlideChange}
              onSlideChange={onSlideChange}
            >
              {data.items.map((el) => (
                <SwiperSlide key={el.seriesId}>
                  <SeriesCard
                    size="big"
                    selected={el.followInfo.isFollowed}
                    showAddBtn={user ? true : false}
                    showCount
                    {...el}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <EmptyContainer>
              <EmptyText>No series</EmptyText>
            </EmptyContainer>
          )}
        </ListWrapper>
      </div>
      <AlertModal
        open={isUnfollowSeriesModalOpen}
        onCancel={closeUnfollowSeriesModal}
        onPrimaryBtnClick={() => onToggleSeriesFollow(selectedSeriesId, true)}
        onSecondaryBtnClick={closeUnfollowSeriesModal}
        confirmLoading={toggleSeriesFollowLoading || loading}
        title="Unfollow?"
        description="You can follow the Series back later again"
        primaryBtnText="UNFOLLOW"
        secondaryBtnText="CANCEL"
      />
    </>
  )
}

export default Series
