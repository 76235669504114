import { ReactComponent as NextIcon } from 'assets/icons/slider-arrow-next-small.svg'
import { ReactComponent as PreviousIcon } from 'assets/icons/slider-arrow-previous-small.svg'
import { Flex } from 'components/common/Flex'
import dayjs from 'dayjs'
import {
  getShiurimOrderedByDate,
  getShiurimOrderedByDateVariables,
} from 'graphql/query/__generated__/getShiurimOrderedByDate'
import { GET_SHIURIM_ORDERED_BY_DATE } from 'graphql/query/getShiurimOrderedByDate'
import { useQueryInfo, withQueryInfo } from 'hooks/useQueryInfo'
import { Ref, useEffect, useMemo, useRef, useState } from 'react'
import { ROUTE_PATH } from 'routes'
import { SwiperClass, SwiperRef } from 'swiper/react'

import { useQuery } from '@apollo/client'

import PlayAllShiursByDateButton from '../../Shiurim/PlayAllShiursByDateButton'
import Calendar from '../Calendar'
import { ButtonsContainer, TitleDivider, TitleRow, ViewAllLink } from '../Following/styled'
import { Title } from '../NewAndRecommended/styled'
import ShiurimList from '../ShiurimList'
import { SliderButton } from './styled'

type Props = { openRegisterToContinueModal: () => void }

const initialPagination = {
  take: 10,
  page: 1,
}

function NewShiurim({ openRegisterToContinueModal }: Props) {
  const { setQueryInfo } = useQueryInfo()

  const swiperRef: Ref<SwiperRef> = useRef()

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(true)
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false)

  const variables = useMemo(() => ({ date: selectedDate.toDateString(), ...initialPagination }), [selectedDate])
  const { data, loading, fetchMore } = useQuery<getShiurimOrderedByDate, getShiurimOrderedByDateVariables>(
    GET_SHIURIM_ORDERED_BY_DATE,
    { variables, fetchPolicy: 'cache-and-network' }
  )

  useEffect(() => {
    setQueryInfo({
      query: GET_SHIURIM_ORDERED_BY_DATE,
      variables,
    })
  }, [setQueryInfo, variables])

  const onFetchMore = async () => {
    setLoadingNext(true)

    const variables = { take: initialPagination.take, page: page + 1, date: selectedDate.toDateString() }
    await fetchMore({
      variables,
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getShiurimOrderedByDate: {
            ...previousQueryResult.getShiurimOrderedByDate,
            ...fetchMoreResult.getShiurimOrderedByDate,
            items: [
              ...previousQueryResult.getShiurimOrderedByDate.items,
              ...fetchMoreResult.getShiurimOrderedByDate.items,
            ],
          },
        }
      },
    })

    setQueryInfo({
      query: GET_SHIURIM_ORDERED_BY_DATE,
      variables,
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const onSlideChange = (swiper: SwiperClass) => {
    if (swiper.isBeginning) setIsPrevBtnDisabled(true)
    if (swiper.isEnd) setIsNextBtnDisabled(true)

    if (!swiper.isBeginning) setIsPrevBtnDisabled(false)
    if (!swiper.isEnd) setIsNextBtnDisabled(false)
  }

  return (
    <Flex column>
      <Flex justifyBetween alignCenter>
        <TitleRow>
          <Flex alignCenter>
            <Title>Daily Shiurim</Title>
            <TitleDivider />
            <ViewAllLink to={ROUTE_PATH.DAILY_SHIURIM}>View all</ViewAllLink>
          </Flex>
          <PlayAllShiursByDateButton date={dayjs(selectedDate)} />
        </TitleRow>
        <ButtonsContainer>
          <SliderButton disabled={isPrevBtnDisabled} onClick={() => swiperRef.current.swiper.slidePrev()}>
            <PreviousIcon />
          </SliderButton>
          <SliderButton disabled={isNextBtnDisabled} onClick={() => swiperRef.current.swiper.slideNext()}>
            <NextIcon />
          </SliderButton>
        </ButtonsContainer>
      </Flex>
      <Calendar
        swiperRef={swiperRef}
        selectedDate={selectedDate}
        onChangeDate={setSelectedDate}
        onSlideChange={onSlideChange}
      />
      <ShiurimList
        items={data?.getShiurimOrderedByDate?.items}
        next={data?.getShiurimOrderedByDate?.meta?.next}
        loading={loading}
        loadingNext={loadingNext}
        onFetchMore={onFetchMore}
        openRegisterToContinueModal={openRegisterToContinueModal}
      />
    </Flex>
  )
}

export default withQueryInfo(NewShiurim)
