/* eslint-disable no-console */
import { UserInputDto } from '__generated__/globalTypes'
import { createUserSession, createUserSessionVariables } from 'graphql/mutation/__generated__/createUserSession'
import { updateUser, updateUserVariables } from 'graphql/mutation/__generated__/updateUser'
import { CREATE_USER_SESSION } from 'graphql/mutation/createUserSession'
import { UPDATE_USER } from 'graphql/mutation/updateUser'
import {
  getSeriesFollowedByUser,
  getSeriesFollowedByUserVariables,
} from 'graphql/query/__generated__/getSeriesFollowedByUser'
import { getUser, getUser_getUser } from 'graphql/query/__generated__/getUser'
import {
  getUsersSession,
  getUsersSessionVariables,
  getUsersSession_getUsersSession,
} from 'graphql/query/__generated__/getUsersSession'
import { GET_SERIES_FOLLOWED_BY_USER } from 'graphql/query/getSeriesFollowedByUser'
import { GET_USER } from 'graphql/query/getUser'
import { GET_USERS_SESSION } from 'graphql/query/getUsersSession'
import { sendSomethingWrongNotification } from 'helpers/sendSomethingWrongNotification'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import LocalStorage from 'utils/localStorage'

import { useLazyQuery, useMutation } from '@apollo/client'
import { Auth, CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'

type User = getUser_getUser & {
  isSeriesFollowed: boolean
  usersSessionData: getUsersSession_getUsersSession
  provider?: string
}

type Context = {
  user: User | null | undefined
  isLoading: boolean
  actions: {
    updateUserInfo: (data: UserInputDto) => Promise<void>
    signOut: () => Promise<void>
    checkAuth: () => Promise<void>
    signIn: (username: string, password: string) => Promise<void>
    signInWith: (provider: CognitoHostedUIIdentityProvider) => Promise<void>
    signUp: (email: string, password: string) => Promise<boolean>
    confirmSignUp: (email: string, code: string) => Promise<void>
    resendSignUp: (email: string) => Promise<void>
    changeEmail: (newEmail: string) => Promise<void>
    confirmChangeEmail: (code: string) => Promise<void>
    changePassword: (oldPassword: string, newPassword: string) => Promise<void>
    resetPassword: (username: string) => Promise<void>
    confirmResetPassword: (username: string, code: string, password: string) => Promise<void>
  }
}

const AuthContext = createContext<Context>({
  user: undefined,
  isLoading: true,
  actions: {
    updateUserInfo: async () => void 0,
    checkAuth: async () => void 0,
    signIn: async () => void 0,
    signInWith: async () => void 0,
    signUp: async () => true,
    confirmSignUp: async () => void 0,
    resendSignUp: async () => void 0,
    signOut: async () => void 0,
    changeEmail: async () => void 0,
    confirmChangeEmail: async () => void 0,
    changePassword: async () => void 0,
    resetPassword: async () => void 0,
    confirmResetPassword: async () => void 0,
  },
})

export const AuthProvider = (props: { children?: ReactNode }) => {
  const [state, setState] = useState(useContext(AuthContext))

  const [getUser] = useLazyQuery<getUser>(GET_USER, {
    fetchPolicy: 'network-only',
    context: { headers: { 'x-platform': 'web' } },
  })
  const [getUsersSession] = useLazyQuery<getUsersSession, getUsersSessionVariables>(GET_USERS_SESSION, {
    fetchPolicy: 'network-only',
  })
  const [createUserSessionMutation] = useMutation<createUserSession, createUserSessionVariables>(CREATE_USER_SESSION)
  const [getFollowedSeries] = useLazyQuery<getSeriesFollowedByUser, getSeriesFollowedByUserVariables>(
    GET_SERIES_FOLLOWED_BY_USER,
    { fetchPolicy: 'network-only', variables: { take: 1, page: 1 } }
  )
  const [updateUser] = useMutation<updateUser, updateUserVariables>(UPDATE_USER)

  async function updateUserInfo(input: UserInputDto) {
    try {
      const { data } = await updateUser({ variables: { createUserData: input } })

      setState({
        ...state,
        user: { ...state.user, ...data?.updateUser },
      })
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function setUser(cognitoUser: CognitoUser | null) {
    if (cognitoUser) {
      LocalStorage.token = cognitoUser.getSignInUserSession()?.getAccessToken().getJwtToken() as string

      try {
        const { data } = await getUser()
        const { data: followedSeriesData } = await getFollowedSeries()

        const { data: usersSessionData } = await getUsersSession({
          variables: { sessionParams: { deviceDetails: null, sessionKey: null } },
        })

        let usersSession = usersSessionData.getUsersSession
        if (!usersSessionData.getUsersSession) {
          const { data: createdUserSessionData } = await createUserSessionMutation({
            variables: {
              sessionParams: { deviceDetails: null, sessionKey: null },
              sessionData: { playerSpeed: 1 },
            },
          })
          usersSession = createdUserSessionData.createUserSession
        }

        if (!data?.getUser) {
          throw new Error("Couldn't find user.")
        }

        const provider = JSON.parse((cognitoUser as any)?.attributes?.identities || '[]')?.[0]?.providerName
        setState({
          ...state,
          isLoading: false,
          user: {
            ...data?.getUser,
            isSeriesFollowed: !!followedSeriesData?.getSeriesFollowedByUser?.items?.length,
            usersSessionData: usersSession,
            provider,
          },
        })
      } catch (e) {
        sendSomethingWrongNotification()
        setState({
          ...state,
          isLoading: false,
        })
        await signOut()
      }
    } else {
      LocalStorage.token = null
      setState({
        ...state,
        isLoading: false,
        user: null,
      })
    }
  }

  async function signOut(): Promise<void> {
    try {
      await Auth.signOut()
      await setUser(null)
    } catch (error) {
      console.error('[Auth]', error)
    }
  }

  async function signIn(username: string, password: string): Promise<void> {
    try {
      const user = await Auth.signIn({ username: username.toLowerCase(), password })

      await setUser(user)
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function signInWith(provider: CognitoHostedUIIdentityProvider): Promise<void> {
    try {
      await Auth.federatedSignIn({ provider })
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function signUp(email: string, password: string): Promise<boolean> {
    const lowerCasedEmail = email.toLowerCase()
    try {
      const { userConfirmed } = await Auth.signUp({
        username: lowerCasedEmail,
        password,
        attributes: {
          email: lowerCasedEmail,
        },
        autoSignIn: { enabled: true },
      })
      return userConfirmed
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function confirmSignUp(email: string, code: string): Promise<void> {
    try {
      await Auth.confirmSignUp(email.toLowerCase(), code)
      setState({
        ...state,
        isLoading: true,
      })
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function resendSignUp(email: string): Promise<void> {
    try {
      await Auth.resendSignUp(email.toLowerCase())
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function changeEmail(newEmail: string): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.updateUserAttributes(user, {
        email: newEmail,
      })
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function confirmChangeEmail(code: string): Promise<void> {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code)
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function changePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function resetPassword(username: string): Promise<void> {
    try {
      await Auth.forgotPassword(username.toLowerCase())
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function confirmResetPassword(username: string, code: string, password: string): Promise<void> {
    try {
      await Auth.forgotPasswordSubmit(username.toLowerCase(), code, password)
    } catch (error) {
      console.error('[Auth]', error)
      throw error
    }
  }

  async function checkAuth(): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser()

      await setUser(user)
    } catch (error) {
      setState((prev) => ({
        ...prev,
        isLoading: false,
      }))
      console.error('[Auth]', error)
    }
  }

  useEffect(() => {
    Hub.listen('auth', async ({ payload }) => {
      const { event, data } = payload

      switch (event) {
        case 'autoSignIn':
        case 'cognitoHostedUI':
          await setUser(data)
          break
        case 'updateUserAttributes':
          await checkAuth()
          break
        case 'oAuthSignOut':
          await setUser(null)
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.error('[Auth] Sign in failure:', data)
          break
      }
    })

    checkAuth()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        actions: {
          updateUserInfo,
          checkAuth,
          signIn,
          signInWith,
          signUp,
          confirmSignUp,
          resendSignUp,
          signOut,
          changeEmail,
          confirmChangeEmail,
          changePassword,
          resetPassword,
          confirmResetPassword,
        },
      }}
      {...props}
    />
  )
}

export function useAuth() {
  const ctx = useContext(AuthContext)

  return {
    user: ctx.user,
    isLoading: ctx.isLoading,
    ...ctx.actions,
  }
}
