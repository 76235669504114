import { TablePaginationConfig } from 'antd'
import { ReactComponent as DonationHistoryIcon } from 'assets/icons/donation-history.svg'
import DonationHistoryTable from 'components/business/Main/DonationHistoryTable'
import Header from 'components/ui/Header'
import NoData from 'components/ui/NoData'
import { DonateActive } from 'components/ui/Sidebar/icons'
import {
  getSponsorshipHistory,
  getSponsorshipHistoryVariables,
} from 'graphql/query/__generated__/getSponsorshipHistory'
import { GET_SPONSORSHIP_HISTORY } from 'graphql/query/getSponsorshipHistory'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useQuery } from '@apollo/client'

import { Wrapper } from '../styled'

function DonationHistory() {
  const wrapperRef = useRef<HTMLDivElement>()
  const navigate = useNavigate()

  const countOfItemsToFillHeight = wrapperRef.current?.clientHeight
    ? Math.floor((wrapperRef.current?.clientHeight - 250) / 70)
    : undefined

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 50,
  })

  useEffect(() => {
    if (!countOfItemsToFillHeight) return
    setPagination((prev) => ({ ...prev, pageSize: countOfItemsToFillHeight }))
  }, [countOfItemsToFillHeight])

  const { data, loading } = useQuery<getSponsorshipHistory, getSponsorshipHistoryVariables>(GET_SPONSORSHIP_HISTORY, {
    variables: { page: pagination.current, take: pagination.pageSize },
    onCompleted: (data) => setPagination((prev) => ({ ...prev, total: data?.getSponsorshipHistory?.meta?.total })),
  })

  const noDataButtonProps = { buttonText: 'DONATE', onClick: () => navigate(ROUTE_PATH.DONATE) }

  const items = data?.getSponsorshipHistory?.items
  return (
    <>
      <Header icon={<DonateActive />} title="Donations history" />
      <Wrapper ref={wrapperRef}>
        {!items?.length && !loading ? (
          <NoData
            icon={<DonationHistoryIcon />}
            title="You haven't made any donations yet"
            description="Make your first donation and it will show up on this screen"
            buttonProps={noDataButtonProps}
          />
        ) : (
          <DonationHistoryTable
            pagination={pagination}
            onChangePagination={setPagination}
            items={items}
            loading={loading}
          />
        )}
      </Wrapper>
    </>
  )
}

export default DonationHistory
