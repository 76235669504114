import { ReactComponent as DonationsIcon } from 'assets/icons/avatar-donations.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/avatar-logout.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/avatar-profile.svg'
import { ReactComponent as BackIcon } from 'assets/icons/back-arrow.svg'
import { ReactComponent as DownArrowIcon } from 'assets/icons/down-arrow.svg'
import { ReactComponent as NotificationIcon } from 'assets/icons/notification.svg'
import AvatarFallback from 'assets/img/avatar-fallback.jpg'
import NotificationsPopoverContent from 'components/business/Main/Notifications/NotificationsPopoverContent'
import { ArrowIconTogglerWrapper } from 'components/common/ArrowIconToggler'
import { Flex } from 'components/common/Flex'
import { deviceWidth } from 'constants/responsive'
import { useAuth } from 'hooks/useAuth'
import { useHeaderHeight } from 'hooks/useHeaderHeight'
import { ChangeEvent, ReactNode, memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import AlertModal from '../Modal/AlertModal'
import NavigationMenu from '../NavigationMenu'
import {
  Avatar,
  AvatarPopover,
  AvatarPopoverItem,
  AvatarPopoverText,
  AvatarText,
  ClearText,
  ClearWrapper,
  Divider,
  IconWrapper,
  MobileNavigationPopover,
  NotificationWrapper,
  NotificationsPopover,
  ProfileWrapper,
  SearchStyled,
  Title,
  TitleDivider,
  ViewAllLink,
  Wrapper,
} from './styled'

export type SearchProps = {
  searchValue: string
  onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void
  searchPlaceholder: string
}

type ClearProps = {
  icon: ReactNode
  text: string
  onClick: () => void
}

type ViewAllProps = {
  to: string
}

type Props = {
  title: string
  isBackButton?: boolean
  icon?: ReactNode
  searchProps?: SearchProps
  clearProps?: ClearProps
  viewAllProps?: ViewAllProps
}

function Header({ title, isBackButton, icon, searchProps, clearProps, viewAllProps }: Props) {
  const { setHeaderHeight } = useHeaderHeight()
  const { user, signOut } = useAuth()
  const navigate = useNavigate()
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [isAvatarPopoverOpen, setIsAvatarPopoverOpen] = useState(false)
  const [isNavigationPopoverOpen, setIsNavigationPopoverOpen] = useState(false)
  const [isClearModalOpen, setIsClearModalOpen] = useState(false)

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const [logoutLoading, setLogoutLoading] = useState(false)

  useEffect(() => {
    const height = document.querySelector('header').offsetHeight

    setHeaderHeight(height)
  }, [setHeaderHeight])

  const openClearModal = () => setIsClearModalOpen(true)
  const closeClearModal = () => setIsClearModalOpen(false)

  const openLogoutModal = () => setIsLogoutModalOpen(true)
  const closeLogoutModal = () => setIsLogoutModalOpen(false)

  const onCloseNotifications = () => setIsNotificationsOpen(false)

  const onProfileClick = () => {
    navigate(ROUTE_PATH.PROFILE)
  }

  const onDonationHistoryClick = () => {
    navigate(ROUTE_PATH.DONATION_HISTORY)
  }

  const onLogin = () => {
    if (!user) return navigate(ROUTE_PATH.LOGIN)
  }

  const onLogout = () => {
    openLogoutModal()
    setIsAvatarPopoverOpen(false)
  }
  const onCloseLogoutModal = async (confirmed?: boolean) => {
    setLogoutLoading(true)

    if (confirmed) {
      await signOut()
    }
    closeLogoutModal()

    setLogoutLoading(false)
  }

  const isMobile = window.innerWidth < Number(deviceWidth.laptopL.match(/(\d+)/)[0])

  return (
    <>
      <Wrapper isSearch={!!searchProps}>
        <Flex justifyBetween alignCenter>
          <Flex flexOne alignCenter>
            <IconWrapper onClick={isBackButton ? () => navigate(-1) : undefined} clickable={isBackButton}>
              {isBackButton ? <BackIcon /> : icon}
            </IconWrapper>
            <MobileNavigationPopover
              trigger="click"
              open={isMobile ? isNavigationPopoverOpen : false}
              onOpenChange={setIsNavigationPopoverOpen}
              content={<NavigationMenu />}
            >
              {viewAllProps && !isMobile ? (
                <Flex alignCenter>
                  <Title>{title}</Title>
                  <TitleDivider />
                  <ViewAllLink to={viewAllProps.to}>All series</ViewAllLink>
                </Flex>
              ) : (
                <Flex alignEnd>
                  <Title>{title}</Title>
                  {isMobile && (
                    <ArrowIconTogglerWrapper isOpen={isNavigationPopoverOpen}>
                      <DownArrowIcon />
                    </ArrowIconTogglerWrapper>
                  )}
                </Flex>
              )}
            </MobileNavigationPopover>
            {searchProps && !isMobile && (
              <SearchStyled
                header
                placeholder={searchProps.searchPlaceholder}
                value={searchProps.searchValue}
                onChange={searchProps.onChangeSearch}
              />
            )}
            {clearProps &&
              (!isMobile ? (
                <>
                  <Divider />
                  <ClearWrapper onClick={openClearModal}>
                    {clearProps.icon}
                    <ClearText>{clearProps.text}</ClearText>
                  </ClearWrapper>
                </>
              ) : (
                <>
                  <Divider />
                  <ClearWrapper onClick={openClearModal}>{clearProps.icon}</ClearWrapper>{' '}
                </>
              ))}
          </Flex>
          <Flex alignCenter>
            <NotificationsPopover
              placement={isMobile ? undefined : 'bottomRight'}
              trigger="click"
              open={isNotificationsOpen}
              onOpenChange={setIsNotificationsOpen}
              content={<NotificationsPopoverContent onClose={onCloseNotifications} />}
            >
              <NotificationWrapper>
                <NotificationIcon />
              </NotificationWrapper>
            </NotificationsPopover>
            {user ? (
              <AvatarPopover
                placement="bottomRight"
                trigger="click"
                open={isAvatarPopoverOpen}
                onOpenChange={setIsAvatarPopoverOpen}
                content={
                  <div>
                    <AvatarPopoverItem onClick={onProfileClick}>
                      <ProfileIcon />
                      <AvatarPopoverText>Profile</AvatarPopoverText>
                    </AvatarPopoverItem>
                    <AvatarPopoverItem onClick={onDonationHistoryClick}>
                      <DonationsIcon />
                      <AvatarPopoverText>Donations history</AvatarPopoverText>
                    </AvatarPopoverItem>
                    <AvatarPopoverItem onClick={onLogout}>
                      <LogoutIcon />
                      <AvatarPopoverText>Log out</AvatarPopoverText>
                    </AvatarPopoverItem>
                  </div>
                }
              >
                <ProfileWrapper>
                  <Avatar
                    src={user.avatarUrl ? `${process.env.REACT_APP_AWS_S3_URL}${user.avatarUrl}` : AvatarFallback}
                  />
                  <ArrowIconTogglerWrapper isOpen={isAvatarPopoverOpen}>
                    <DownArrowIcon />
                  </ArrowIconTogglerWrapper>
                </ProfileWrapper>
              </AvatarPopover>
            ) : (
              <ProfileWrapper onClick={onLogin}>
                <Avatar src={AvatarFallback} />
                <AvatarText>LOG IN</AvatarText>
              </ProfileWrapper>
            )}
          </Flex>
        </Flex>
        {searchProps && isMobile && (
          <SearchStyled
            isMobile
            header
            placeholder={searchProps.searchPlaceholder}
            value={searchProps.searchValue}
            onChange={searchProps.onChangeSearch}
          />
        )}
      </Wrapper>
      {clearProps && (
        <AlertModal
          open={isClearModalOpen}
          onCancel={closeClearModal}
          onPrimaryBtnClick={clearProps.onClick}
          onSecondaryBtnClick={closeClearModal}
          title={`${clearProps.text}?`}
          primaryBtnText="CLEAR"
          secondaryBtnText="CANCEL"
        />
      )}
      <AlertModal
        open={isLogoutModalOpen}
        onCancel={() => onCloseLogoutModal()}
        onPrimaryBtnClick={() => onCloseLogoutModal(true)}
        onSecondaryBtnClick={() => onCloseLogoutModal()}
        confirmLoading={logoutLoading}
        title="Are you sure?"
        description="You’ll be logged out of your account."
        primaryBtnText="LOG OUT"
        secondaryBtnText="CANCEL"
      />
    </>
  )
}

export default memo(Header)
