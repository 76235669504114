import ShiurimList from 'components/business/Main/Lists/ShiurimList'
import ShiurimFilter, { ShiurimFilters } from 'components/business/Main/Shiurim/ShiurimFilter'
import Header from 'components/ui/Header'
import AlertModal from 'components/ui/Modal/AlertModal'
import { ShiurimActive } from 'components/ui/Sidebar/icons'
import { getAllShiurims, getAllShiurimsVariables } from 'graphql/query/__generated__/getAllShiurims'
import { GET_ALL_SHIURIMS } from 'graphql/query/getAllShiurims'
import useDebouncedValue from 'hooks/useDebouncedValue'
import { useHeaderHeight } from 'hooks/useHeaderHeight'
import { usePlayer } from 'hooks/usePlayer'
import { useQueryInfo, withQueryInfo } from 'hooks/useQueryInfo'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useQuery } from '@apollo/client'

import { FiltersWrapper, Wrapper } from './styled'

const initialPagination = {
  take: 30,
  page: 1,
}

function Shiurim() {
  const { setQueryInfo } = useQueryInfo()
  const { headerHeight } = useHeaderHeight()
  const { play, pause, isInitialized } = usePlayer()
  const [searchParams, setSearchParams] = useSearchParams()
  const sharedShiursIds = searchParams.get('id')
  const navigate = useNavigate()

  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)
  const [filters, setShiurimFilters] = useState<ShiurimFilters>({})
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebouncedValue(sharedShiursIds ? '' : search, 300)

  const searchProps = {
    searchValue: search,
    onChangeSearch: (e: ChangeEvent<HTMLInputElement>) => {
      if (sharedShiursIds) {
        searchParams.delete('id')
        setSearchParams(searchParams)
      }

      setSearch(e.target.value)
    },
    searchPlaceholder: 'Search shiurim...',
  }

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  const variables = useMemo(
    () => ({
      ...initialPagination,
      searchParams: {
        title: debouncedSearch,
        played: filters.played?.value,
        startDate: filters.period?.startDate,
        endDate: filters.period?.endDate,
        series: filters.series?.map((el) => el.id),
        topics: filters.topics?.map((el) => el.id),
        subtopics: filters.subtopics?.map((el) => el.id),
        ...(sharedShiursIds ? { shiurims: sharedShiursIds.split(',') } : {}),
      },
    }),
    [
      debouncedSearch,
      filters.played?.value,
      filters.period?.endDate,
      filters.period?.startDate,
      filters.series,
      filters.subtopics,
      filters.topics,
      sharedShiursIds,
    ]
  )
  const { data, loading, fetchMore } = useQuery<getAllShiurims, getAllShiurimsVariables>(GET_ALL_SHIURIMS, {
    variables,
    fetchPolicy: 'cache-and-network',
    async onCompleted(data) {
      if (sharedShiursIds) {
        const sharedShiur = data.getAllShiurims.items.find((el) => el.shiurId === sharedShiursIds.split(',')[0])

        if (sharedShiursIds.split(',').length === 1) {
          setSearch(sharedShiur.title)

          if (!isInitialized) {
            await play(sharedShiur)
            pause()
          }
        } else {
          setSearch(`${sharedShiur.title} +${sharedShiursIds.split(',').length - 1}`)
        }
      }
    },
  })

  useEffect(() => {
    setQueryInfo({ query: GET_ALL_SHIURIMS, variables })
  }, [setQueryInfo, variables])

  const onFetchMore = async () => {
    setLoadingNext(true)

    const variables = { take: initialPagination.take, page: page + 1 }
    await fetchMore({
      variables,
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getAllShiurims: {
            ...previousQueryResult.getAllShiurims,
            ...fetchMoreResult.getAllShiurims,
            items: [...previousQueryResult.getAllShiurims.items, ...fetchMoreResult.getAllShiurims.items],
          },
        }
      },
    })

    setQueryInfo({ query: GET_ALL_SHIURIMS, variables })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)

  const onChangeShiurimFilters = (filters: ShiurimFilters) => {
    setShiurimFilters(filters)

    if (sharedShiursIds) {
      searchParams.delete('id')
      setSearchParams(searchParams)
      setSearch('')
    }
  }

  return (
    <>
      <Header icon={<ShiurimActive />} title="Shiurim" searchProps={searchProps} />
      <FiltersWrapper>
        <ShiurimFilter withQuickFilters filters={filters} onChangeFilters={onChangeShiurimFilters} />
      </FiltersWrapper>
      <Wrapper id="scrollable-wrapper" headerHeight={headerHeight}>
        <ShiurimList
          items={data?.getAllShiurims?.items}
          next={data?.getAllShiurims?.meta?.next}
          onFetchMore={onFetchMore}
          loading={loading}
          loadingNext={loadingNext}
          openRegisterToContinueModal={openRegisterToContinueModal}
        />
      </Wrapper>
      <AlertModal
        open={isRegisterToContinueModalOpen}
        onCancel={closeRegisterToContinueModal}
        onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
        onSecondaryBtnClick={closeRegisterToContinueModal}
        title="Register to Continue"
        description="To unlock all features you need to create an account"
        primaryBtnText="REGISTER"
        secondaryBtnText="NOT NOW"
      />
    </>
  )
}

export default withQueryInfo(Shiurim)
