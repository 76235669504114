import { ReactComponent as PlayIcon } from 'assets/icons/play-medium.svg'
import { ReactComponent as PlayingIcon } from 'assets/icons/playing.svg'
import { Flex } from 'components/common/Flex'
import { getAnnouncementsWithPagination_getAnnouncementsWithPagination_items } from 'graphql/query/__generated__/getAnnouncementsWithPagination'
import { usePlayer } from 'hooks/usePlayer'

import {
  DateText,
  LabelText,
  LabelWrapper,
  PlayButtonWrapper,
  ShiurContentWrapper,
  ShiurDescription,
  ShiurImage,
  ShiurNumber,
  ShiurTitle,
  ShiurTitleRow,
  ShiurWrapper,
  Text,
  Title,
  Wrapper,
} from './styled'

type Props = getAnnouncementsWithPagination_getAnnouncementsWithPagination_items & { showLabel?: boolean }

function SpecialPodcastCard({ title, text, publicationDate, shiur, showLabel }: Props) {
  const { id, isPlaying, play, pause } = usePlayer()

  const isCurrentInitialized = id === shiur?.shiurId
  const isCurrentPlaying = isPlaying && isCurrentInitialized

  const togglePlayer = async () => {
    if (isPlaying && isCurrentInitialized) {
      return pause()
    }

    if (isCurrentInitialized && !isPlaying) {
      return await play()
    }

    if (!isCurrentInitialized) {
      await play(shiur)
    }
  }

  return (
    <Wrapper borderless={showLabel}>
      <div>
        {showLabel ? (
          <>
            <Flex justifyBetween alignCenter>
              <LabelWrapper>
                <LabelText>Special podcasts</LabelText>
              </LabelWrapper>
              <DateText>
                {new Date(publicationDate).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </DateText>
            </Flex>
            <Title>{title}</Title>
          </>
        ) : (
          <Flex justifyBetween alignCenter>
            <Title>{title}</Title>
            <DateText>
              {new Date(publicationDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </DateText>
          </Flex>
        )}

        <Text>{text}</Text>
      </div>
      <ShiurWrapper>
        <Flex>
          <ShiurImage src={`${process.env.REACT_APP_AWS_S3_URL}${shiur?.series.coverUrl}`} />
          <ShiurContentWrapper>
            <ShiurTitleRow>
              {!!shiur?.orderedNum && <ShiurNumber>Shiur {shiur?.orderedNum}.</ShiurNumber>}
              <ShiurTitle>{shiur?.title}</ShiurTitle>
            </ShiurTitleRow>
            {shiur?.subtitle && <ShiurDescription>{shiur?.subtitle}</ShiurDescription>}
          </ShiurContentWrapper>
        </Flex>
        <PlayButtonWrapper onClick={togglePlayer}>
          {isCurrentPlaying ? <PlayingIcon /> : <PlayIcon />}
        </PlayButtonWrapper>
      </ShiurWrapper>
    </Wrapper>
  )
}

export default SpecialPodcastCard
