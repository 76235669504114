import { ReactComponent as SearchIcon } from 'assets/icons/search-big.svg'
import NoData from 'components/ui/NoData'
import {
  getSeriesWithPagination,
  getSeriesWithPaginationVariables,
} from 'graphql/query/__generated__/getSeriesWithPagination'
import { GET_SERIES_WITH_PAGINATION } from 'graphql/query/getSeriesWithPagination'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import SeriesList from '../../Lists/SeriesList'

type Props = { search: string }

const initialPagination = {
  take: 50,
  page: 1,
}

function SeriesTab({ search }: Props) {
  const { user } = useAuth()

  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  useEffect(() => {
    setPage(initialPagination.page)
  }, [search])

  const { data, loading, fetchMore } = useQuery<getSeriesWithPagination, getSeriesWithPaginationVariables>(
    GET_SERIES_WITH_PAGINATION,
    {
      variables: { title: search, ...initialPagination },
    }
  )

  const onFetchMore = async () => {
    setLoadingNext(true)

    await fetchMore({
      variables: { take: initialPagination.take, page: page + 1 },
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getSeriesWithPagination: {
            ...previousQueryResult.getSeriesWithPagination,
            ...fetchMoreResult.getSeriesWithPagination,
            items: [
              ...previousQueryResult.getSeriesWithPagination.items,
              ...fetchMoreResult.getSeriesWithPagination.items,
            ],
          },
        }
      },
    })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  if (!data?.getSeriesWithPagination?.items?.length && !loading)
    return (
      <NoData
        height="calc(100% - 82px)"
        icon={<SearchIcon />}
        title="Sorry, we couldn't find any matches"
        description="Try searching again using a different spelling or keyword"
      />
    )

  return (
    <SeriesList
      size="huge"
      showCount
      items={data?.getSeriesWithPagination?.items}
      next={data?.getSeriesWithPagination?.meta?.next}
      onFetchMore={onFetchMore}
      loading={loading}
      loadingNext={loadingNext}
    />
  )
}

export default SeriesTab
