import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { sendSomethingWrongNotification } from 'helpers/sendSomethingWrongNotification'
import LocalStorage from 'utils/localStorage'

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Auth } from '@aws-amplify/auth'

const authLink = setContext((_, { headers }) => {
  const token = LocalStorage.token
  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: token,
      },
    }
  }
  return {
    headers,
  }
})

async function refreshToken() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const refreshToken = cognitoUser.getSignInUserSession().getRefreshToken()
    LocalStorage.token = await new Promise((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, (error: Error | null, session: CognitoUserSession) => {
        if (error) {
          reject(error)
        }

        resolve(session.getIdToken().getJwtToken())
      })
    })
  } catch (error) {
    console.error('[Auth] Unable to refresh token:', error)
    LocalStorage.token = null
    throw error
  }
  return LocalStorage.token
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (networkError) {
    sendSomethingWrongNotification()
    console.error(`[Network error]: ${networkError}`)
  }
  if (graphQLErrors) {
    for (const graphQLError of graphQLErrors) {
      const { message, path } = graphQLError
      if (message === 'jwt expired') {
        return new Observable((observer) => {
          refreshToken()
            .then((token) => {
              operation.setContext({
                headers: {
                  ...operation.getContext().headers,
                  Authorization: token,
                },
              })

              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              }

              forward(operation).subscribe(subscriber)
            })
            .catch((error: Error) => {
              observer.error(error)
            })
        })
      } else {
        sendSomethingWrongNotification()
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
      }
    }
  }

  return
})

const httpLink = new HttpLink({
  fetchOptions: { mode: 'cors' },
  uri: `${process.env.REACT_APP_API_URL}`,
})

export const apolloClient = new ApolloClient({
  connectToDevTools: true,
  cache: new InMemoryCache({
    typePolicies: {
      UserOutputDto: { keyFields: ['userId'] },
      SeriesOutputDto: { keyFields: ['seriesId'] },
      ShiurimOutputDto: { keyFields: ['shiurId'] },
      ShiurimHistoryOutputDto: { keyFields: ['shiurId'] },
      BookmarkOutputDto: { keyFields: ['bookmarkId'] },
      NoteOutputDto: { keyFields: ['noteId'] },
    },
  }),
  link: ApolloLink.from([errorLink, authLink, httpLink]),
})
