import { Form as ANTDForm, Input } from 'antd'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Description,
  ErrorText,
  FakeLink,
  Form,
  FormItem,
  FullWidthPageContainer,
  FullWidthPageContent,
  FullWidthPageHeader,
  FullWidthPageWrapper,
  Heading,
  SubmitButton,
} from '../styled'

function VerifyEmail() {
  const { confirmSignUp, resendSignUp } = useAuth()
  const { state } = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [isErrors, setIsErrors] = useState(false)
  const [form] = Form.useForm()

  const code = Form.useWatch('code', { form })

  useEffect(() => {
    if (code) {
      form
        .validateFields()
        .then(() => setIsErrors(false))
        .catch(() => {
          setIsErrors(true)
        })
    }
  }, [code, form])

  const resendCode = async () => {
    setError('')
    try {
      await resendSignUp(state.email)
    } catch (e) {
      setError(e.message)
    }
  }

  const onSubmit = async (values: { code: string }) => {
    setIsLoading(true)
    setError('')
    try {
      await confirmSignUp(state.email, values.code)
    } catch (e) {
      setError(e.message)
    }
    setIsLoading(false)
  }

  const isDisabled = !code || isErrors

  return (
    <>
      <FullWidthPageWrapper>
        <FullWidthPageHeader>
          <Logo />
        </FullWidthPageHeader>
        <FullWidthPageContainer>
          <FullWidthPageContent>
            <Heading>Confirm email</Heading>
            <Description>Confirm your email by entering verification code</Description>
            <Form form={form} layout="vertical" onFinish={onSubmit} hideRequiredMark>
              <FormItem
                name="code"
                label="Verification code"
                required
                rules={[
                  { required: true, message: '' },
                  { pattern: new RegExp('[0-9]'), message: 'Incorrect code' },
                ]}
              >
                <Input maxLength={6} placeholder="Enter your verification code" status={error ? 'error' : undefined} />
              </FormItem>
              {error && <ErrorText>{error}</ErrorText>}
              <FakeLink onClick={resendCode}>Resend code</FakeLink>
              <ANTDForm.Item noStyle>
                <SubmitButton htmlType="submit" type="primary" disabled={isDisabled} loading={isLoading}>
                  CONFIRM
                </SubmitButton>
              </ANTDForm.Item>
            </Form>
          </FullWidthPageContent>
        </FullWidthPageContainer>
      </FullWidthPageWrapper>
    </>
  )
}

export default VerifyEmail
