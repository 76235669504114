import { ReactComponent as SearchIcon } from 'assets/icons/search-big.svg'
import AlertModal from 'components/ui/Modal/AlertModal'
import NoData from 'components/ui/NoData'
import { getAllShiurims, getAllShiurimsVariables } from 'graphql/query/__generated__/getAllShiurims'
import { GET_ALL_SHIURIMS } from 'graphql/query/getAllShiurims'
import { useQueryInfo } from 'hooks/useQueryInfo'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import { useQuery } from '@apollo/client'

import ShiurimList from '../../Lists/ShiurimList'
import { ShiurimFilters } from '../../Shiurim/ShiurimFilter'

type Props = { search: string; filters: ShiurimFilters }

const initialPagination = {
  take: 30,
  page: 1,
}

function ShiurimTab({ search, filters }: Props) {
  const navigate = useNavigate()
  const { setQueryInfo } = useQueryInfo()

  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)
  const [page, setPage] = useState(initialPagination.page)
  const [loadingNext, setLoadingNext] = useState(false)

  useEffect(() => {
    setPage(initialPagination.page)
  }, [search])

  const variables = useMemo(
    () => ({
      ...initialPagination,
      searchParams: {
        title: search,
        played: filters.played?.value,
        startDate: filters.period?.startDate,
        endDate: filters.period?.endDate,
        series: filters.series?.map((el) => el.id),
        topics: filters.topics?.map((el) => el.id),
        subtopics: filters.subtopics?.map((el) => el.id),
      },
    }),
    [
      filters.period?.endDate,
      filters.period?.startDate,
      filters.played?.value,
      filters.series,
      filters.subtopics,
      filters.topics,
      search,
    ]
  )
  const { data, loading, fetchMore } = useQuery<getAllShiurims, getAllShiurimsVariables>(GET_ALL_SHIURIMS, {
    variables,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setQueryInfo({ query: GET_ALL_SHIURIMS, variables })
  }, [setQueryInfo, variables])

  const onFetchMore = async () => {
    setLoadingNext(true)

    const variables = { take: initialPagination.take, page: page + 1 }
    await fetchMore({
      variables,
      updateQuery(previousQueryResult, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          getAllShiurims: {
            ...previousQueryResult.getAllShiurims,
            ...fetchMoreResult.getAllShiurims,
            items: [...previousQueryResult.getAllShiurims.items, ...fetchMoreResult.getAllShiurims.items],
          },
        }
      },
    })

    setQueryInfo({ query: GET_ALL_SHIURIMS, variables })

    setPage((prev) => prev + 1)
    setLoadingNext(false)
  }

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)

  if (!data?.getAllShiurims?.items?.length && !loading)
    return (
      <NoData
        height="calc(100% - 82px)"
        icon={<SearchIcon />}
        title="Sorry, we couldn't find any matches"
        description="Try searching again using a different spelling or keyword"
      />
    )

  return (
    <>
      <ShiurimList
        items={data?.getAllShiurims?.items}
        next={data?.getAllShiurims?.meta?.next}
        onFetchMore={onFetchMore}
        openRegisterToContinueModal={openRegisterToContinueModal}
        loading={loading}
        loadingNext={loadingNext}
      />
      <AlertModal
        open={isRegisterToContinueModalOpen}
        onCancel={closeRegisterToContinueModal}
        onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
        onSecondaryBtnClick={closeRegisterToContinueModal}
        title="Register to Continue"
        description="To unlock all features you need to create an account"
        primaryBtnText="REGISTER"
        secondaryBtnText="NOT NOW"
      />
    </>
  )
}

export default ShiurimTab
