import { Spin } from 'antd'
import Loader from 'components/ui/Loader'
import AlertModal from 'components/ui/Modal/AlertModal'
import { getSeriesWithPagination_getSeriesWithPagination_items } from 'graphql/query/__generated__/getSeriesWithPagination'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATH } from 'routes'

import SeriesCard, { SeriesSize } from '../../Cards/SeriesCard'
import { EmptyContainer, EmptyText } from '../../Home/styled'
import { LoaderWrapper, Wrapper } from './styled'

type Props = {
  items?: getSeriesWithPagination_getSeriesWithPagination_items[]
  size?: SeriesSize
  showCount?: boolean
  showAddBtn?: boolean
  onFetchMore?: () => void
  next?: boolean
  loading: boolean
  loadingNext: boolean
}

function SeriesList({ items, size, showCount, showAddBtn = true, onFetchMore, next, loading, loadingNext }: Props) {
  const navigate = useNavigate()
  const { user } = useAuth()

  const isSmall = size === 'small'
  const isBig = size === 'big'
  const isHuge = size === 'huge'
  const isItems = !!items?.length

  const [isRegisterToContinueModalOpen, setIsRegisterToContinueModalOpen] = useState(false)

  const openRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(true)
  const closeRegisterToContinueModal = () => setIsRegisterToContinueModalOpen(false)

  if (loading) return <Loader noHeight />
  return (
    <>
      <Wrapper
        small={isSmall}
        big={isBig}
        huge={isHuge}
        dataLength={items?.length || 0}
        next={onFetchMore}
        hasMore={next}
        loader={null}
        grid={isItems}
        scrollableTarget="scrollable-wrapper"
      >
        {!isItems ? (
          <EmptyContainer>
            <EmptyText>No series</EmptyText>
          </EmptyContainer>
        ) : (
          items?.map((el) => (
            <SeriesCard
              key={el.seriesId}
              size={size}
              selected={el.followInfo.isFollowed}
              showAddBtn={showAddBtn}
              showCount={showCount}
              onAddBtnClick={user ? undefined : openRegisterToContinueModal}
              {...el}
            />
          ))
        )}
        <LoaderWrapper>
          <Spin spinning={loadingNext} />
        </LoaderWrapper>
      </Wrapper>
      <AlertModal
        open={isRegisterToContinueModalOpen}
        onCancel={closeRegisterToContinueModal}
        onPrimaryBtnClick={() => navigate(ROUTE_PATH.REGISTRATION)}
        onSecondaryBtnClick={closeRegisterToContinueModal}
        title="Register to Continue"
        description="To unlock all features you need to create an account"
        primaryBtnText="REGISTER"
        secondaryBtnText="NOT NOW"
      />
    </>
  )
}

export default SeriesList
