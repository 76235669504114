import { Form, Input } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import { sendQuestion, sendQuestionVariables } from 'graphql/mutation/__generated__/sendQuestion'
import { SEND_QUESTION } from 'graphql/mutation/sendQuestion'
import { useAuth } from 'hooks/useAuth'
import { ErrorText } from 'pages/Auth/styled'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'

import { ANTDModal, Divider, SubmitButton, TextArea, Title } from './styled'

type Props = { open: boolean; onClose: () => void; nextStep: (email: string) => void }

function InputEmailAndText({ open, onClose, nextStep }: Props) {
  const { user } = useAuth()

  const [form] = useForm()
  const email = useWatch('email', form)

  const [error, setError] = useState('')
  const [isErrors, setIsErrors] = useState(false)
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)

  const [sendQuestion] = useMutation<sendQuestion, sendQuestionVariables>(SEND_QUESTION)

  const validateAndUpdateState = useCallback(() => {
    form
      .validateFields()
      .then(() => setIsErrors(false))
      .catch(() => {
        setIsErrors(true)
      })
  }, [form])

  const onSave = async () => {
    setLoading(true)
    setError('')

    try {
      await sendQuestion({ variables: { question: text, email } })
      nextStep(email)
    } catch (e) {
      setError(e.message)
    }

    setLoading(false)
  }

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value)

  const isFooterVisible = user ? !!text : !!(text && email && !isErrors)
  return (
    <ANTDModal
      open={open}
      onCancel={() => onClose()}
      width={420}
      destroyOnClose={true}
      isFooterVisible={isFooterVisible}
      title={<Title>Questions and Comments</Title>}
      footer={
        isFooterVisible ? (
          <SubmitButton type="primary" loading={loading} onClick={onSave}>
            SEND
          </SubmitButton>
        ) : (
          <></>
        )
      }
    >
      {!user && (
        <>
          <Form form={form} layout="vertical" requiredMark={false}>
            <Form.Item
              name="email"
              required
              validateTrigger={form.getFieldError('email').length ? 'onChange' : 'onBlur'}
              rules={[
                { required: true, message: '' },
                {
                  type: 'email',
                  message: 'Incorrect email',
                },
              ]}
            >
              <Input
                name="email"
                placeholder="Enter your email address"
                onChange={form.getFieldError('email').length ? validateAndUpdateState : undefined}
                onBlur={form.getFieldError('email').length ? undefined : validateAndUpdateState}
              />
            </Form.Item>
          </Form>
          <Divider />
        </>
      )}
      <TextArea
        maxLength={300}
        placeholder="Ask any question or share a comment. We will email you a response..."
        value={text}
        onChange={onChange}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </ANTDModal>
  )
}

export default InputEmailAndText
